<template>
  <div class="file-upload">
    <label v-if="label" class="font-poppings font-semibold">{{ label }}</label>
    <div v-if="!file" class="mt-2">
      <input
        ref="input"
        type="file"
        class="hidden"
        @change="handleSelectFile"
      />
      <div class="flex items-center" v-if="allowEditInvoice()">
        <Button
          variant="input"
          class="rounded-r-none border-r-0 w-full text-left whitespace-nowrap"
          @click="handleTriggerFileInput"
        >
          {{ selectFileLabel }}
        </Button>
        <Button
          class="rounded-l-none whitespace-nowrap"
          :disabled="!selectedFile"
          @click="uploadFile"
          v-if="allowEditInvoice()"
        >
          <span>Upload</span>
          <upload-icon
            class="h-4 w-4 text-black inline-block align-middle ml-1"
          />
        </Button>
      </div>
    </div>
    <div
      v-else
      class="bg-gray-100 border border-gray-500 py-2 px-4 flex rounded-md shadow items-center justify-between mt-2"
    >
      <span class="text-gray-500 flex items-center">
        <invoices-icon class="w-4 h-4 inline-block mr-4" />
        <span>{{ file.title }}</span>
      </span>
      <span class="text-gray-500 flex items-center space-x-2">
        <button @click="deleteFile" v-if="allowEditInvoice()">
          <close-icon class="h-4 w-4 inline-block stroke-2" />
        </button>
        <a :href="file.url" target="_blank">
          <external-link-icon class="w-4 h-4 inline-block" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useToast } from '@/helpers/composables';
import { useStore } from 'vuex';
import { useAllow } from '@/helpers/composables';

export default {
  props: {
    id: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    file: {
      type: Object,
      default: () => null,
    },
    invoiceType: {
      type: String,
      default: () => '',
    },
    fileName: {
      type: String,
      default: () => '',
    },
    invoiceNumber: {
      type: String,
      default: () => '',
    },
  },
  emits: ['fileUploaded', 'fileDeleted'],
  setup(props, { emit }) {
    const toast = useToast();
    const input = ref(null);
    const selectedFile = ref(null);
    const store = useStore();
    const selectFileLabel = computed(() =>
      selectedFile.value ? selectedFile.value.name : 'Select file'
    );
    const allowEditInvoice = () => allow('invoices', 'create|edit');

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    function handleTriggerFileInput() {
      input.value.click();
    }

    function handleSelectFile(e) {
      selectedFile.value = e.target.files[0];
    }

    async function uploadFile() {
      const formData = new FormData();
      formData.append('invoiceType', props.invoiceType);
      formData.append('invoiceNumber', props.invoiceNumber);
      formData.append('fileName', props.fileName);
      formData.append('files', selectedFile.value);

      await store.dispatch('post', {
        action: '/invoices/documents',
        formData,
      });
      selectedFile.value = null;
      toast.show('File uploaded');
      emit('fileUploaded', true);
    }

    async function deleteFile() {
      const { message } = await store.dispatch('delete', {
        action: `/invoices/documents/${props.id}`,
      });
      toast.show(message);
      emit('fileDeleted', true);
    }

    return {
      input,
      handleTriggerFileInput,
      handleSelectFile,
      selectedFile,
      selectFileLabel,
      uploadFile,
      deleteFile,
      allowEditInvoice,
    };
  },
};
</script>
