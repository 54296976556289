<template>
  <section class="steps lg:px-20">
    <div class="step"
          :class="data.status && (data.status.phrase == invoiceStatus.IN_PROGRESS || data.status.phrase == invoiceStatus.AWAITING_REVISION) ? 'active': 'complete'"
    >
      <div>
        <span class="indicator">
          <span>1</span>
        </span>
        <span class="label">Edit</span>
      </div>
      <span class="line"></span>
    </div>

    <div class="step"
      :class="{'active': (data.status && data.status.phrase == invoiceStatus.AWAITING_APPROVAL_CSM), 'complete': (data.status && data.status.phrase != invoiceStatus.IN_PROGRESS  && data.status.phrase != invoiceStatus.AWAITING_REVISION && data.status.phrase != invoiceStatus.AWAITING_APPROVAL_CSM)}"
    >
      <div>
        <span class="indicator">
          <span>2</span>
        </span>
        <span class="label">Awaiting Approval - CSM</span>
      </div>
      <span class="line"></span>
    </div>
    <div class="step"
      :class="{'active': (data.status && data.status.phrase == invoiceStatus.AWAITING_APPROVAL_AP), 'complete': (data.status && data.status.phrase != invoiceStatus.IN_PROGRESS  && data.status.phrase != invoiceStatus.AWAITING_REVISION && data.status.phrase != invoiceStatus.AWAITING_APPROVAL_CSM && data.status.phrase != invoiceStatus.AWAITING_APPROVAL_AP)}"
    >
      <div>
        <span class="indicator">
          <span>3</span>
        </span>
        <label class="label">Awaiting Approval - AP</label>
      </div>
      <span class="line"></span>
    </div>
    <div class="step"
        :class="{'active': data.status && data.status.phrase == invoiceStatus.APPROVED, 'complete': data.status && data.status.phrase == invoiceStatus.INVOICE_ISSUED}"
    >
      <div>
        <span class="indicator">
          <span>4</span>
        </span>
        <label class="label">Send to Pfizer</label>
      </div>
    </div>
  </section>
</template>
<script>
import constant from '@/helpers/constant';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(){
    const invoiceStatus = constant.INVOICE_STATUS;
    return {
       invoiceStatus
    };
  }
};
</script>

<style lang="postcss" scoped>
.steps {
  @apply mt-16 grid grid-cols-4;
}
.step {
  @apply w-full flex justify-start items-center relative left-1/2;
}
.step > div {
  @apply relative;
}
.step .indicator {
  @apply bg-gray-300 text-gray-500 w-12 h-12 text-center font-poppins font-semibold flex flex-col justify-around relative z-10;
  border-radius: 50%;
}
.step .label {
  @apply absolute top-full text-center mt-2 left-1/2 transform -translate-x-1/2;
}
.line {
  @apply border-gray-300 border-b-2 h-2 block relative flex-1;
}
.step.complete .indicator {
  @apply bg-black text-white;
}
.step.active .indicator {
  @apply bg-yellow-500 text-black;
}
.step .label {
  @apply text-gray-500 font-poppins whitespace-nowrap;
}
.step.active .label {
  @apply text-black font-semibold;
}
.step.complete .label {
  @apply text-black;
}
.step.complete .line {
  @apply border-black;
}
</style>
