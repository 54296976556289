<template>
  <div class="flex flex-wrap" v-if="!is_sent">
    <div class="bg-gray-100 p-12 w-1/2">
      <h1 class="font-bold py-2">Your Current Invoice</h1>
      <div class="bg-white mt-4 p-6 shadow rounded-md">
        <ViewPDF :pdfSource="pdfSource"></ViewPDF>
      </div>
    </div>
    <div class="bg-white p-28 w-1/2">
      <Form
        :initial-values="presetsEmailDetails"
        method="post"
        :action="`/invoices/${param}/send-to-pfizer`"
        @success="handleSuccess"
        :validation-schema="validationSchema"
      >
        <h1 class="font-bold">Select files to attach in the email:</h1>
        <div class="py-4">
          <div
            class="flex items-center py-1"
            v-for="(item, index) in invoiceDocuments"
            :key="index"
            :item="item"
          >
            <div
              class="flex items-center"
              v-if="
                invoiceDocumentType.PREVIOUS_INVOICE ==
                item.invoiceDocumentTypeId
              "
            >
              <Checkbox :modelValue="item.id" @update="updatedCheckbox" />
              <p class="pl-2 text-gray-700 font-poppins">Previous Invoice</p>
            </div>
            <div
              class="flex items-center"
              v-if="
                invoiceDocumentType.ENTRIES_BY_JOB == item.invoiceDocumentTypeId
              "
            >
              <Checkbox :modelValue="item.id" @update="updatedCheckbox" />
              <p class="pl-2 text-gray-700 font-poppins">
                EBJ (Entries by Job) Report (SAGE)
              </p>
            </div>
            <div
              class="flex items-center"
              v-if="
                invoiceDocumentType.JOB_COST_TO_DATE ==
                item.invoiceDocumentTypeId
              "
            >
              <Checkbox :modelValue="item.id" @update="updatedCheckbox" />
              <p class="pl-2 text-gray-700 font-poppins">
                Job Cost to Date Report (SAGE)
              </p>
            </div>
            <div
              class="flex items-center"
              v-if="
                invoiceDocumentType.BUDGET_REPORT == item.invoiceDocumentTypeId
              "
            >
              <Checkbox :modelValue="item.id" @update="updatedCheckbox" />
              <p class="pl-2 text-gray-700 font-poppins">
                Budget Report (Procore)
              </p>
            </div>
            <div
              class="flex items-center"
              v-if="
                invoiceDocumentType.OTHER_DOCUMENTS ==
                item.invoiceDocumentTypeId
              "
            >
              <Checkbox :modelValue="item.id" @update="updatedCheckbox" />
              <p class="pl-2 text-gray-700 font-poppins">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>

        <Field class="invisible" name="docuList"></Field>

        <Field name="emailRecipient" label="Email Recipient:" bold />
        <Field name="subject" label="Subject:" bold class="py-4" />
        <Field name="fileName" label="File Name:" bold class="py-4" />
        <Field
          name="message"
          component="textarea"
          label="Compose Message:"
          bold
          class="py-4"
        />
        <Button :variant="primary" class="w-40 mt-8 mb-12 font-bold"
          >Send Invoice</Button
        >
      </Form>
    </div>
  </div>
  <div
    class="flex flex-wrap"
    v-if="
      is_sent ||
      (invoiceDetails &&
        invoiceDetails.status.phrase == invoiceStatus.INVOICE_ISSUED)
    "
  >
    <div class="bg-gray-100 p-12 w-full">
      <div class="flex flex-wrap justify-between items-center">
        <p class="py-2">Invoice Issued {{ invoiceUpdatedDate }}</p>
        <div class="flex">
          <Form
            :initial-values="presetsEmailDetails"
            method="post"
            :action="`/invoices/${param}/resend-to-pfizer`"
            @success="handleSuccess"
          >
            <Field
              name="emailRecipient"
              label="Email Recipient:"
              bold
              style="display: none"
            />
            <Field name="subject" label="Subject:" bold style="display: none" />
            <Field
              name="fileName"
              label="File Name:"
              bold
              style="display: none"
            />
            <Field
              name="message"
              component="textarea"
              label="Compose Message:"
              bold
              style="display: none"
            />
            <Button class="mr-2" variant="secondary" v-if="allowEditInvoice()"
              >Resend Invoice</Button
            >
          </Form>

          <Button class="ml-2" @click="downloadInvoice()"
            >Download Invoice PDF</Button
          >
        </div>
      </div>

      <div class="bg-white mt-8 p-6 shadow rounded-md">
        <ViewPDF :pdfSource="pdfSource"></ViewPDF>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import ViewPDF from '@/components/PDF/ViewPDF.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast, useAllow } from '@/helpers/composables';
import moment from 'moment';
import constant from '@/helpers/constant';
import { object, string } from 'yup';

export default {
  components: {
    ViewPDF,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const pdfSource = ref('');
    const is_sent = ref();
    const { id: param } = route.params;
    const presetsEmailDetails = ref({});
    const invoiceDocuments = ref();
    const invoiceDetails = ref();
    const invoiceUpdatedDate = ref();
    const invoiceStatus = constant.INVOICE_STATUS;
    const { user } = store.state.user;
    const allowEditInvoice = () => allow('invoices', 'create|edit');
    const invoiceDocumentType = constant.INVOICE_DOCUMENT_TYPE;

    onMounted(() => {
      getPresetsEmailDetails();
      getInvoiceDocument();
      getInvoiceDetails();
    });

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    function downloadInvoice() {
      var downloadLink = document.createElement('a');
      downloadLink.href = pdfSource.value;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    async function getInvoiceDetails() {
      const { data } = await store.dispatch('get', {
        action: `/invoices/${param}`,
      });
      invoiceDetails.value = data;
      invoiceUpdatedDate.value = moment(invoiceDetails.value.updatedAt).format(
        'MM/DD/YYYY'
      );
      presetsEmailDetails.value.fileName =
        invoiceDetails.value.invoiceNumber +
        ' ' +
        invoiceDetails.value.id +
        ' ' +
        invoiceDetails.value.invoiceDate.replaceAll('-', '');
      is_sent.value = data.status.title == 'Invoice Issued' ? true : false;
      if (invoiceDocuments.value && invoiceDocuments.value.length > 0) {
        if (data.concatenatedDocUrl) {
          pdfSource.value = data.concatenatedDocUrl;
        } else {
          getContactedPDF();
        }
      } else {
        pdfSource.value = data.documentUrl;
      }
    }
    async function getContactedPDF() {
      const { data } = await store.dispatch('post', {
        action: '/invoices/' + param + '/concatenate',
      });

      pdfSource.value = data ? data.invoiceUrl : '';
    }

    async function getPresetsEmailDetails() {
      const { data } = await store.dispatch('get', {
        action: '/presets/email-settings/1',
      });
      presetsEmailDetails.value = data;
      presetsEmailDetails.value.docuList = [];
      presetsEmailDetails.value.emailRecipient = data.recipients;
    }

    async function getInvoiceDocument() {
      const { data } = await store.dispatch('get', {
        action: `/invoices/documents/${param}`,
      });
      invoiceDocuments.value = data;
    }

    function updatedCheckbox(update) {
      if (update.checked == true) {
        if (presetsEmailDetails.value.docuList.length) {
          if (!presetsEmailDetails.value.docuList.includes(update.value)) {
            presetsEmailDetails.value.docuList.push(update.value);
          }
        } else {
          presetsEmailDetails.value.docuList.push(update.value);
        }
      }
      if (update.checked == false) {
        if (presetsEmailDetails.value.docuList.length) {
          if (presetsEmailDetails.value.docuList.includes(update.value)) {
            const index = presetsEmailDetails.value.docuList.indexOf(
              update.value
            );
            if (index > -1) {
              presetsEmailDetails.value.docuList.splice(index, 1);
            }
          }
        }
      }
    }

    function handleSuccess() {
      updateInvoiceStatus(invoiceStatus.INVOICE_ISSUED);
      createNotification(
        constant.NOTIFICATION_TYPE.INVOICE_ISSUED,
        'Invoice Issued'
      );
      is_sent.value = true;
    }

    async function updateInvoiceStatus(status) {
      const formData = {
        statusId: status,
        redirectLink: window.location.href,
      };
      try {
        const response = await store.dispatch('patch', {
          action: `/invoices/${param}`,
          formData,
        });
        toast.show(response.message);
      } catch (err) {
        console.log(err);
      }
    }

    async function createNotification(type, message = '') {
      const formData = {
        type: type,
        message: message,
        read: false,
        userId: user.id,
        invoiceId: param,
      };
      try {
        await store.dispatch('post', {
          action: `/notifications`,
          formData,
        });
        toast.show('Notification created');
      } catch (err) {
        console.log(err);
      }
    }

    const validationSchema = object({
      emailRecipient: string()
        .transform((value) => value.replace(' ', ';'))
        .matches(
          //  /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4},?)+$/,
          // /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/,
          /^(([A-Za-z0-9._%+-]+@{1}[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,4}),?)+$/,
          'Recipients must be a list of valid email addresses, separated by commas and no spaces.'
        )
        .required(),
    });

    return {
      validationSchema,
      pdfSource,
      downloadInvoice,
      is_sent,
      presetsEmailDetails,
      invoiceDocuments,
      updatedCheckbox,
      handleSuccess,
      invoiceDetails,
      invoiceUpdatedDate,
      param,
      invoiceStatus,
      createNotification,
      updateInvoiceStatus,
      allowEditInvoice,
      invoiceDocumentType,
    };
  },
};
</script>
