<template>
  <section class="mt-24 flex">
    <button
      class="text-gray-400 font-poppins text-2xl text-center w-1/3 py-6 border-b-8 border-transparent"
      :class="showSetupScreen?'border-yellow-500' : 'border-transparent'"
      @click="showSetup()"
    >
      Setup
    </button>
    <button
      class="text-black font-poppins text-2xl text-center font-medium w-1/3 py-6 border-b-8 border-transparent"
      :class="showReviewScreen?'border-yellow-500' : 'border-transparent'"
      @click="showReview()"
    >
      Review
    </button>
    <button
      class="text-gray-400 font-poppins text-2xl text-center w-1/3 py-6 border-b-8 border-transparent"
      :class="showSendScreen?'border-yellow-500' : 'border-transparent'"
      @click="showSend()"
    >
      Send
    </button>
  </section>
</template>
<script>
import { ref, watch, onMounted } from 'vue';
import constant from '@/helpers/constant';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['showScreen'],
  setup(props, { emit }) {
    var showSetupScreen = ref();
    var showReviewScreen = ref();
    var showSendScreen = ref();

    watch(
      () => props.data, 
      (newValue) => {
        getUpdatedPhrase(newValue.status.phrase);
        emit('showScreen',[
            {'showSetupScreen' : showSetupScreen.value},
            {'showReviewScreen':showReviewScreen.value},
            {'showSendScreen':showSendScreen.value}
          ]
        ); 
      }
    );
    
  
    onMounted(() => {
      getUpdatedPhrase(props.data.status.phrase);
    });

    function getUpdatedPhrase(phrase){
      if(phrase == constant.INVOICE_STATUS.IN_PROGRESS || phrase == constant.INVOICE_STATUS.AWAITING_REVISION || phrase == constant.INVOICE_STATUS.OFFLINE){
          showSetupScreen.value = true;
          showReviewScreen.value = false;
          showSendScreen.value = false;
        }
        if(phrase == constant.INVOICE_STATUS.AWAITING_APPROVAL_CSM || phrase == constant.INVOICE_STATUS.AWAITING_APPROVAL_AP){
          showSetupScreen.value = false;
          showReviewScreen.value = true;
          showSendScreen.value = false;
        }
        if(phrase == constant.INVOICE_STATUS.APPROVED || phrase == constant.INVOICE_STATUS.INVOICE_ISSUED){
          showSetupScreen.value = false;
          showReviewScreen.value = false;
          showSendScreen.value = true;
        }
    }

    function showSetup() {
      showSetupScreen.value = true;
      showReviewScreen.value = false;
      showSendScreen.value = false;
      emit('showScreen',[
          {'showSetupScreen' : showSetupScreen.value},
          {'showReviewScreen':showReviewScreen.value},
          {'showSendScreen':showSendScreen.value}
        ]
      ); 
    }
    function showReview() {
      showSetupScreen.value = false;
      showReviewScreen.value = true;
      showSendScreen.value = false;
      emit('showScreen', [
          {'showSetupScreen' : showSetupScreen.value},
          {'showReviewScreen':showReviewScreen.value},
          {'showSendScreen':showSendScreen.value}
        ]
      ); 
    }
    function showSend() {
      showSetupScreen.value = false;
      showReviewScreen.value = false;
      showSendScreen.value = true;
      emit('showScreen', [
          {'showSetupScreen' : showSetupScreen.value},
          {'showReviewScreen':showReviewScreen.value},
          {'showSendScreen':showSendScreen.value}
        ]
      );  
    }
    return{
      showSetupScreen,
      showReviewScreen,
      showSendScreen,
      showSetup,
      showReview,
      showSend,
      getUpdatedPhrase
    };
  }
};
</script>

