<template>
  <div class="bg-white shadow rounded-md mt-16">
    <h3
      class="font-poppings font-medium text-xl px-8 py-4 border-b border-gray-300"
    >
      Supporting Documents
    </h3>
    <div class="grid grid-cols-2 px-24 py-8 gap-x-64 gap-y-8">
      <FileUpload 
        label="Previous Invoice:" 
        invoiceType="previous_invoice" 
        :invoiceNumber="invoiceNumber"
        v-if="!previousInvoice"
        @fileUploaded="fileUpdate"
         />
      <FileUpload 
        label="Previous Invoice:" 
        :file="{ title: previousInvoice.title, url: previousInvoice.url }"
        :id="previousInvoice && previousInvoice.id"
        v-if="previousInvoice"
        @fileDeleted="fileUpdate"
      />

      <FileUpload 
        label="EBJ (Entries by Job) Report (SAGE):" 
        invoiceType="entries_by_job" 
        v-if="!entriesByJob"
        @fileUploaded="fileUpdate"
        :invoiceNumber="invoiceNumber"
      />

      <FileUpload 
        label="EBJ (Entries by Job) Report (SAGE):" 
        :file="{ title: entriesByJob.title, url: entriesByJob.url }" 
        :id="entriesByJob && entriesByJob.id"
        v-if="entriesByJob"
        @fileDeleted="fileUpdate"
      />

      <FileUpload
        label="Job Cost to Date Report (SAGE):"
        invoiceType="job_cost_to_date"
        v-if="!jobCostToDate"
        @fileUploaded="fileUpdate"
        :invoiceNumber="invoiceNumber"
      />

      <FileUpload 
        label="Job Cost to Date Report (SAGE):" 
        :file="{ title: jobCostToDate.title, url: jobCostToDate.url }" 
        :id="jobCostToDate && jobCostToDate.id"
        v-if="jobCostToDate"
        @fileDeleted="fileUpdate"
      />

      <FileUpload
        label="Budget Report (Procore):"
        invoiceType="budget_report"
        v-if="!budgetReport"
        @fileUploaded="fileUpdate"
        :invoiceNumber="invoiceNumber"
      />
      <FileUpload 
        label="Budget Report (Procore):" 
        :file="{ title: budgetReport.title, url: budgetReport.url }" 
        :id="budgetReport && budgetReport.id"
        v-if="budgetReport"
        @fileDeleted="fileUpdate"
      />
    </div>
    <h3
      class="font-poppings font-medium text-xl px-8 py-4 border-b border-gray-300"
    >
      Additional Documents
    </h3>
    <div class="grid grid-cols-2 px-24 py-8 gap-x-64 gap-y-8" v-if="additionalDocuments">
      <FileUpload
        v-for="(item, index) in additionalDocuments"
        :key="index"
        :item="item"
        label="Document"
        :file="{ title: item.title, url: item.url }"
        :id="item.id"
        @fileDeleted="fileUpdate"
      />
    </div>
    <div class="px-24 pb-12"  v-if="allowEditInvoice()">
      <hr class="text-gray-100" />
      <h4 class="font-poppins mt-6 text-xl">Upload Document</h4>
      <div class="flex mt-4 space-x-16 items-center">
        <Field
          label="File Name:"
          placeholder="File Name"
          wrapperClass="flex items-center"
          labelClass="mr-2"
          bold
          :modelValue="additionalDocumentFileName"
          @input="handleChange"
        />
        <FileUpload class="w-1/4" invoiceType="other_document" :fileName="additionalDocumentFileName" :invoiceNumber="invoiceNumber" @fileUploaded="fileUpdate" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref,onMounted, watch } from 'vue';
import FileUpload from '@/components/forms/base/FileUpload.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useAllow  } from '@/helpers/composables';
export default {
  components: {
    FileUpload,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { id: param } = route.params;
    const previousInvoice = ref();
    const entriesByJob = ref();
    const jobCostToDate = ref();
    const budgetReport = ref();
    const additionalDocuments = ref([]);
    const additionalDocumentFileName = ref('');
    const invoiceNumber = ref();
    const invoiceDocumentData  = ref ();
    const allowEditInvoice = () => allow('invoices', 'create|edit');

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }
    
    onMounted(() => {
      getInvoiceDetails();
      getInvoiceDocuments();
    });

    watch(
      () => invoiceDocumentData.value,
      (newValue) => {
        additionalDocuments.value = [];
        previousInvoice.value = '';
        entriesByJob.value = '';
        jobCostToDate.value = '';
        budgetReport.value = '';

        newValue.map((el) => {
          if(el.invoiceDocumentTypeId == 1){
            previousInvoice.value = el;
          }
          if(el.invoiceDocumentTypeId == 2){
            entriesByJob.value = el;
          }
          if(el.invoiceDocumentTypeId == 3){
            jobCostToDate.value = el;
          }
          if(el.invoiceDocumentTypeId == 4){
            budgetReport.value = el;
          }
          if(el.invoiceDocumentTypeId == 5){
            additionalDocuments.value.push(el);
          }
        });
      });
    
    async function getInvoiceDetails() {
        const { data } = await store.dispatch('get', {
            action: '/invoices',
            param,
        });
        invoiceNumber.value = data.invoiceNumber;
    }
    async function getInvoiceDocuments(){
      const { data } = await store.dispatch('get', {
        action: `/invoices/documents/${param}`
      });
      invoiceDocumentData.value = data;
    }
    function handleChange(e){
      additionalDocumentFileName.value = e.target._value;
    }

    function fileUpdate(event) {
      if(event) {
        additionalDocumentFileName.value = '';
        getInvoiceDocuments();
      };
    }
    return {
      getInvoiceDocuments,
      previousInvoice,
      entriesByJob,
      jobCostToDate,
      budgetReport,
      additionalDocuments,
      handleChange,
      additionalDocumentFileName,
      fileUpdate,
      invoiceNumber,
      invoiceDocumentData,
      allowEditInvoice
    };
  },
};
</script>
