<template>
  <Modal id="preview-pdf" title="Preview PDF" :close-action="handleClose">
    <template  #default="{}">
      <ViewPDF :pdfSource="invoicePDFSource"  v-if="invoicePDFSource?.length"></ViewPDF>
    </template>
  </Modal>
</template>
<script>
import { ref, onMounted } from 'vue';
import ViewPDF from '@/components/PDF/ViewPDF.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  components: {
   ViewPDF
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const invoicePDFSource = ref();
    const { id: param } = route.params;
    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: '/invoices',
        param,
      });
      if(data.documentUrl){
        invoicePDFSource.value = data.documentUrl;
      }
      else {
        generatePDF();
      }
    });

    async function generatePDF() {
      const { invoiceUrl } = await store.dispatch('post', {
        action: '/invoices/'+ param + '/generate-pdf',
      });
      invoicePDFSource.value = invoiceUrl;
    }
    function handleClose(done) {
      done();
    }

    return {
      invoicePDFSource,
      handleClose
    };
  },
};
</script>