<template>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    color="RGB(246, 191, 76)"
    :is-full-page="fullPage"
  />
  <Modal id="review-sign" title="Approve and Sign" :close-action="handleClose">
    <template #default="{ close }">
      <p class="font-bold font-popins">
        Invoice will be signed using this signature
      </p>
      <div
        class="flex justify-center items-center border border-gray-200 border-2 my-4 h-28"
      >
        <img class="w-32" :src="signatureUrl" />
      </div>

      <div class="text-center">
        <Button class="mr-2" @click="addSignature(close)"
          >Approve and Sign</Button
        >
        <Button class="ml-2 w-1/3" variant="secondary" @click="close"
          >Cancel</Button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from '@/helpers/composables';
import constant from '@/helpers/constant';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Loading,
  },
  emits: ['updateStatus'],
  setup(props, { emit }) {
    const id = ref(1);
    const store = useStore();
    const isLoading = ref(false);
    const fullPage = ref(true);
    const signatureUrl = ref();
    const route = useRoute();
    const toast = useToast();
    const { user } = store.state.user;
    const { id: param } = route.params;
    function handleClose(done) {
      done();
    }
    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: `/presets/signature/${id.value}`,
      });
      signatureUrl.value = data.url;
    });

    async function getApproval(close) {
      const formData = {
        statusId: constant.INVOICE_STATUS.AWAITING_APPROVAL_AP,
      };
      try {
        const response = await store.dispatch('patch', {
          action: `/invoices/${param}`,
          formData,
        });
        isLoading.value = false;
        close();
        toast.show(response.message);
        updateInvoiceStatus(constant.INVOICE_STATUS.AWAITING_APPROVAL_AP);
        createNotification(
          constant.NOTIFICATION_TYPE.AWAITING_APPROVAL_AP,
          'Awaiting Approval - AP'
        );
        emit('updateStatus', true);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }

    async function addSignature(close) {
      isLoading.value = true;
      const formData = {
        redirectLink: window.location.href,
      };
      const response = await store.dispatch('post', {
        action: `/invoices/${param}/add-signature`,
        formData,
      });
      if (response.invoiceUrl) getApproval(close);
    }

    async function updateInvoiceStatus(status) {
      const formData = {
        statusId: status,
        redirectLink: window.location.href,
      };
      try {
        const response = await store.dispatch('patch', {
          action: `/invoices/${param}`,
          formData,
        });
        toast.show(response.message);
      } catch (err) {
        console.log(err);
      }
    }

    async function createNotification(type, message = '') {
      const formData = {
        type: type,
        message: message,
        read: false,
        userId: user.id,
        invoiceId: param,
      };
      try {
        await store.dispatch('post', {
          action: `/notifications`,
          formData,
        });
        toast.show('Notification created');
      } catch (err) {
        console.log(err);
      }
    }

    return {
      handleClose,
      signatureUrl,
      getApproval,
      addSignature,
      updateInvoiceStatus,
      createNotification,
      user,
      fullPage,
      isLoading,
    };
  },
};
</script>
