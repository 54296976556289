<template>
    <div class="flex flex-wrap">
        <div class="bg-gray-100 p-12 w-1/2">
            <h1 class="font-bold py-2">Your Current Invoice</h1>
            <div class="bg-white mt-4 p-6 shadow rounded-md">
                <ViewPDF :pdfSource="invoicePDFSource"></ViewPDF>
            </div>
        </div>
        <div class="bg-white p-12 w-1/2">
            <h1 class="font-bold py-2">Choose documents to compare</h1>
            <div class="mt-2 py-3">
                <div class="grid grid-cols-2 gap-2">
                    <button
                        class="font-poppins button rounded-md  border border-gray-500 py-2 px-4"
                        :class="selectedPDF.id == item.id ? 'bg-gray-400 text-white' : 'text-gray-500'"
                        v-for="(item, index) in initialValues"
                        :key="index"
                        :item="item"
                        @click="getSelectedPDF(item.id)"
                    >
                        <span class="flex items-center">
                            <invoices-icon class="w-4 h-4 inline-block mr-4" />
                            <div  v-if="invoiceDocumentType.PREVIOUS_INVOICE == item.invoiceDocumentTypeId">
                                <span class="text-xs">Previous Invoice</span>
                            </div>
                            <div  v-if="invoiceDocumentType.ENTRIES_BY_JOB == item.invoiceDocumentTypeId">
                                <span class="text-xs">EBJ (Entries by Job) Report (SAGE)</span>
                            </div>
                            <div  v-if="invoiceDocumentType.JOB_COST_TO_DATE == item.invoiceDocumentTypeId">
                                <span class="text-xs">Job Cost to Date Report (SAGE)</span>
                            </div>
                            <div  v-if="invoiceDocumentType.BUDGET_REPORT == item.invoiceDocumentTypeId">
                                <span class="text-xs">Budget Report (Procore)</span>
                            </div>
                            <div  v-if="invoiceDocumentType.OTHER_DOCUMENTS == item.invoiceDocumentTypeId">
                                <span class="text-xs"> {{ item.title }}</span>
                            </div>
                        </span>
                    </button>
                </div>
            </div>
            <div class="mt-4">
                <ViewPDF :pdfSource="selectedPDF.url"></ViewPDF>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ViewPDF from '@/components/PDF/ViewPDF.vue';
import constant from '@/helpers/constant';

export default {
    props: {
        invoiceDetails:{
            type: Object,
            default: () => {},
            required: true,
        }
    },
    components: {
        ViewPDF
    },
    setup(props) {
        const store = useStore();
        const route = useRoute();
        const selectedPDF = ref({});
        const invoicePDFSource = ref();
        const initialValues = ref();
        const { id: param } = route.params;
        const invoiceDocumentType = constant.INVOICE_DOCUMENT_TYPE;

    watch(() => props.invoiceDetails,
        (val) => {
            invoicePDFSource.value = val.documentUrl;
    });
    onMounted(()=>{
        invoicePDFSource.value = props.invoiceDetails.documentUrl;
       getInvoiceDocument();
    });

    async function getInvoiceDocument() {
        const { data } = await store.dispatch('get', {
            action: '/invoices/documents',
            param,
        });
        initialValues.value = data;
        selectedPDF.value = data && data[0] ? data[0] : '';
    }

    function getSelectedPDF(id){
        let matchedPDF = initialValues.value.filter((el) => {
            if(el.id == id){
                return el;
            }
        });
        selectedPDF.value = matchedPDF[0];
    }

    return {
      selectedPDF,
      param,
      initialValues,
      invoicePDFSource,
      getSelectedPDF,
      invoiceDocumentType
    };
  }
};
</script>