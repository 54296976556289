<template>
  <loading 
        v-model:active="isLoading"
        :can-cancel="false" 
        color="RGB(246, 191, 76)"
        :is-full-page="fullPage"
    />
  <div>
     <div class="pt-16 bg-white shadow">
        <InvoiceMutilLineHeader v-if="invoiceDetails.status && invoiceDetails.status.phrase == status.OFFLINE" :data="invoiceDetails" />
        <div v-if="invoiceDetails.status && invoiceDetails.status.phrase != status.OFFLINE" :class="!allowEditInvoice()? 'lg:py-20' :''">
          <InvoiceActionBar :data="invoiceDetails" @updateRevisionNotes="updateInvoice" @updateStatus="updateInvoice" 
          />
          <InvoiceSteps :data="invoiceDetails" v-if="allowEditInvoice()"/>
        </div>
        <div v-if="invoiceDetails.status && invoiceDetails.status.phrase != status.OFFLINE && allowEditInvoice()">
          <InvoiceStatus  @showScreen="showScreen"  :data="invoiceDetails"/>
        </div>
        <div class="bg-gray-100 px-24 py-16" v-if="showSetup">
          <RevisionNotes v-if="showRevisionNotes && allowEditInvoice()" @resolved='updateInvoice' :revisionNotes="revisionNotes"/>
          <div class="bg-white p-24 mt-16 shadow rounded-md">
            <InvoiceForm :data="invoiceDetails" @fetch="fetch" :allowMultiLineInvoice="allowMultiLineInvoice" @generatePDF="generatePDF"/>
          </div>
          <InvoiceDocuments />
        </div>
        <div v-if="showReview && allowEditInvoice()">
          <InvoiceReview :invoiceDetails="invoiceDetails" />
        </div>
        <div v-if="showSend">
          <InvoiceSend />
        </div>
      </div> 
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import InvoiceActionBar from '@/components/widgets/InvoiceActionBar.vue';
import InvoiceSteps from '@/components/widgets/InvoiceSteps.vue';
import InvoiceStatus from '@/components/widgets/InvoiceStatus.vue';
import InvoiceForm from '@/components/forms/InvoiceForm.vue';
import InvoiceDocuments from '@/components/widgets/InvoiceDocuments.vue';
import InvoiceReview from '@/components/widgets/InvoiceReview.vue';
import InvoiceSend from '@/components/widgets/InvoiceSend.vue';
import RevisionNotes from '@/components/widgets/RevisionNotes.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import InvoiceMutilLineHeader from '@/components/widgets/InvoiceMutilLineHeader.vue';
import constant from '@/helpers/constant';
import { useToast , useAllow } from '@/helpers/composables';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    InvoiceActionBar,
    InvoiceSteps,
    InvoiceStatus,
    InvoiceForm,
    InvoiceDocuments,
    InvoiceReview,
    InvoiceSend,
    RevisionNotes,
    InvoiceMutilLineHeader,
    Loading
  },
  setup() {
    const showSetup = ref();
    const showReview = ref();
    const showSend = ref();
    const toast = useToast();
    const showRevisionNotes = ref();
    const route = useRoute();
    const store = useStore();
    const { id: param } = route.params;
    const revisionNotes = ref();
    const invoiceDetails = ref({});
    const allowMultiLineInvoice = ref(false);
    const status = ref(constant.INVOICE_STATUS);
    const isLoading= ref(false);
    const fullPage = ref(true);

    const allowEditInvoice = () => allow('invoices', 'create|edit');

    onMounted(() => {
      if (param !== 'new') {
        fetch();
      }
    });

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    async function fetch() {
      const { data } = await store.dispatch('get', {
          action: '/invoices',
          param,
      });
      invoiceDetails.value = data;
      showRevisionNotes.value = data.isResolve == true ? false : true;
      revisionNotes.value = data.rejectionNotes ? data.rejectionNotes : '';

      if(data.status.phrase == status.value.IN_PROGRESS || data.status.phrase == status.value.AWAITING_REVISION){
        showSetup.value = true;
      }
      if(data.status.phrase == status.value.AWAITING_APPROVAL_CSM || data.status.phrase == status.value.AWAITING_APPROVAL_AP){
        showReview.value = true;
      }
      if(data.status.phrase == status.value.APPROVED || data.status.phrase == status.value.INVOICE_ISSUED){
        showSend.value = true;
      }
      if (data.status.phrase == status.value.OFFLINE ) {
          allowMultiLineInvoice.value=true;
          showSetup.value = true;
      }
    }
    function showScreen(data) {
      showSetup.value = data[0].showSetupScreen;
      showReview.value = data[1].showReviewScreen;
      showSend.value = data[2].showSendScreen;
    }
    function updateInvoice(data){
      if(data) fetch();
    }

    async function generatePDF() {
      if(invoiceDetails.value.status.phrase != status.value.OFFLINE){
        toast.show('Generating PDF');
        isLoading.value = true;
        const { invoiceUrl } = await store.dispatch('post', {
          action: '/invoices/'+ param + '/generate-pdf',
        });
        if(invoiceUrl) isLoading.value = false;
        toast.show('PDF Generated');
        fetch();
      }
      else{
        fetch();
      }
    }

    return {
      showSetup,
      showReview,
      showSend,
      showScreen,
      showRevisionNotes,
      fetch,
      updateInvoice,
      revisionNotes,
      invoiceDetails,
      allowMultiLineInvoice,
      status,
      fullPage,
      isLoading,
      generatePDF,
      allowEditInvoice
    };
  }
};
</script>
