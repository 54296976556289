<template>
  <table class="po-table">
    <thead>
      <tr>
        <th align="left">DESCRIPTION</th>
        <th>SCHEDULED VALUE</th>
        <th>PREVIOUS INVOICES</th>
        <th>CURRENT INVOICE</th>
        <th>TOTAL TO DATE</th>
        <th>% COMPLETE</th>
      </tr>
    </thead>
    <tbody>
      <PurchaseOrderTableLineItem
        v-for="(item, index) in lineItems"
        :key="index"
        :item="item"
        :lineItemsData="lineItemsData"
        :index="index"
        @delete="deleteLineItem"
        @update="updateLineItem"
        :invoiceData="invoiceData"
      />
      <tr @click="addLineItem" v-if="allowMultipleLineItems">
        <td
          colspan="100%"
          class="bg-gray-100 text-center p-0 font-semibold font-poppins"
        >
          ADD LINE ITEM
          <plus-circle-icon class="w-6 h-6 inline-block -mt-1 ml-4" />
        </td>
      </tr>
      <tr class="readonly">
        <td class="font-poppins font-semibold text-lg">SUBTOTAL</td>
        <td>{{ aggregate.subtotal.scheduledValue.formatted }}</td>
        <!-- pulled from ukz -->
        <td>{{ aggregate.subtotal.previousInvoices.formatted }}</td>
        <!-- paymentAmount/currentInvoice of previous invoices -->
        <td>{{ aggregate.subtotal.currentInvoice.formatted }}</td>
        <!-- User can edit current invoice -->
        <td>{{ aggregate.subtotal.totalToDate.formatted }}</td>
        <td>{{ aggregate.subtotal.percentComplete }}</td>
      </tr>
      <tr class="readonly">
        <td class="font-poppins font-semibold text-lg">PREVIOUSLY BILLED</td>
        <td></td>
        <td></td>
        <td></td>
        <td>({{ aggregate.previouslyBilled.totalToDate.formatted }})</td>
        <td></td>
      </tr>
      <tr class="readonly">
        <td class="font-poppins font-bold text-lg">TOTAL</td>
        <td></td>
        <td>{{ aggregate.total.previousInvoices.formatted }}</td>
        <td>{{ aggregate.total.currentInvoice.formatted }}</td>
        <td>{{ aggregate.total.totalToDate.formatted }}</td>
        <td></td>
      </tr>
      <tr class="readonly">
        <td class="font-poppins font-bold text-lg">PAY THIS AMOUNT</td>
        <td></td>
        <td></td>
        <td></td>
        <td class="font-bold">{{ aggregate.payment.totalToDate }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref, reactive, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import PurchaseOrderTableLineItem from './PurchaseOrderTableLineItem.vue';
import { useToast } from '@/helpers/composables';
import { number, numberWithCommasAndDecimal } from '@/helpers/math';

export default {
  components: {
    PurchaseOrderTableLineItem,
  },
  props: {
    lineItemsData: {
      type: Object,
      default: () => ({}),
    },
    invoiceData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const allowMultipleLineItems = inject('allowMultipleLineItems');
    const lineItems = ref([]);
    const aggregate = reactive({
      subtotal: {
        scheduledValue: {
          raw: 0,
          formatted: 0,
        },
        previousInvoices: {
          raw: 0,
          formatted: 0,
        },
        currentInvoice: {
          raw: 0,
          formatted: 0,
        },
        totalToDate: {
          raw: 0,
          formatted: 0,
        },
        percentComplete: 0,
      },
      previouslyBilled: {
        totalToDate: {
          raw: 0,
          formatted: 0,
        },
      },
      total: {
        scheduledValue: 0,
        previousInvoices: {
          raw: 0,
          formatted: 0,
        },
        currentInvoice: {
          raw: 0,
          formatted: 0,
        },
        totalToDate: {
          raw: 0,
          formatted: 0,
        },
        percentComplete: 0,
      },
      payment: {
        totalToDate: 0,
      },
    });

    watch(
      () => props.lineItemsData,
      (val) => {
        if (val && !val.length) {
          addLineItem();
        } else if (val && val.length) {
          lineItems.value = val;
        }
      }
    );

    watch(
      () => allowMultipleLineItems,
      (val) => {
        if (!val) {
          const lineItem = lineItems.value[0];
          lineItems.value = [];
          lineItems.value = [...lineItems.value, lineItem];
        }
      }
    );

    function addLineItem() {
      lineItems.value.push({
        invoiceId: props.invoiceData.id,
        description: null,
        scheduledValue: props.invoiceData.lineItems[0].scheduledValue,
        previousInvoices: props.invoiceData.lineItems[0].previousInvoices,
        currentInvoice: 0,
        totalToDate: 0,
      });
    }

    async function deleteLineItem({ index, payload }) {
      lineItems.value.splice(index, 1);
      calculateTotals();
      emit('update', lineItems);
      const { message } = await store.dispatch('delete', {
        action: '/invoices/line-items',
        param: payload.id,
      });
      toast.show(message);
    }

    function updateLineItem({ index, payload }) {
      lineItems.value.splice(index, 1, payload);
      calculateTotals();
      emit('update', lineItems);
    }

    function calculateTotals() {
      // reset raw values
      aggregate.subtotal.scheduledValue.raw = 0;
      aggregate.subtotal.previousInvoices.raw = 0;
      aggregate.subtotal.currentInvoice.raw = 0;

      aggregate.previouslyBilled.totalToDate.raw = 0;

      aggregate.total.scheduledValue = 0;
      aggregate.total.previousInvoices.raw = 0;
      aggregate.total.currentInvoice.raw = 0;

      // raw subtotals
      aggregate.subtotal.scheduledValue.raw += number(
        parseFloat(lineItems.value[0].scheduledValue)
      );
      aggregate.subtotal.previousInvoices.raw += number(
        parseFloat(lineItems.value[0].previousInvoices)
      );

        // formatted subtotals
      aggregate.subtotal.scheduledValue.formatted =
        numberWithCommasAndDecimal(
          roundOff(aggregate.subtotal.scheduledValue.raw)
        );
      aggregate.subtotal.previousInvoices.formatted =
        numberWithCommasAndDecimal(
          roundOff(aggregate.subtotal.previousInvoices.raw)
        );

      // raw previously billed
      aggregate.previouslyBilled.totalToDate.raw += number(
        parseFloat(lineItems.value[0].previousInvoices)
      );

      // raw totals
      aggregate.total.scheduledValue += number(parseFloat(lineItems.value[0].scheduledValue));
      aggregate.total.previousInvoices.raw += number(
        parseFloat(lineItems.value[0].previousInvoices)
      );

      // formatted totals
      aggregate.total.previousInvoices.formatted = numberWithCommasAndDecimal(
        roundOff(aggregate.total.previousInvoices.raw)
      );

      lineItems.value.forEach((e) => {
       // raw subtotals
        aggregate.subtotal.currentInvoice.raw += number(
          parseFloat(e.currentInvoice)
        );
        // formatted subtotals
        aggregate.subtotal.currentInvoice.formatted =
          numberWithCommasAndDecimal(
            roundOff(aggregate.subtotal.currentInvoice.raw)
          );

        // formatted previously billed
        aggregate.previouslyBilled.totalToDate.formatted =
          numberWithCommasAndDecimal(
            roundOff(aggregate.previouslyBilled.totalToDate.raw)
          );

        
        aggregate.total.currentInvoice.raw += number(
          parseFloat(e.currentInvoice)
        );

        
        aggregate.total.currentInvoice.formatted = numberWithCommasAndDecimal(
          roundOff(aggregate.total.currentInvoice.raw)
        );
      });

      // raw calculated subtotals
      aggregate.subtotal.totalToDate.raw = computed(() =>
        number(
          roundOff(parseFloat(aggregate.subtotal.previousInvoices.raw)) +
            roundOff(parseFloat(aggregate.subtotal.currentInvoice.raw))
        )
      );

      // formatted calculated subtotals
      aggregate.subtotal.totalToDate.formatted = numberWithCommasAndDecimal(
        aggregate.subtotal.totalToDate.raw
      );

      // formatted subtotal percent complete
      aggregate.subtotal.percentComplete = computed(() => {
        return `${number(
          Math.round(
            (parseFloat(aggregate.subtotal.totalToDate.raw) /
              parseFloat(aggregate.subtotal.scheduledValue.raw)) *
              100
          )
        )}%`;
      });

      // raw calculated totals
      aggregate.total.totalToDate.raw = computed(() =>
        number(
          roundOff(parseFloat(aggregate.subtotal.totalToDate.raw)) -
            roundOff(parseFloat(aggregate.previouslyBilled.totalToDate.raw))
        )
      );

      // formatted calculated totals
      aggregate.total.totalToDate.formatted = numberWithCommasAndDecimal(
        roundOff(aggregate.total.totalToDate.raw)
      );

      // formatted totals percent complete
      aggregate.total.percentComplete = computed(() => {
        return `${number(
          Math.round(
            (parseFloat(aggregate.total.totalToDate.raw) /
              parseFloat(aggregate.total.scheduledValue)) *
              100
          )
        )}%`;
      });

      // formatted payment
      aggregate.payment.totalToDate = numberWithCommasAndDecimal(
        roundOff(aggregate.total.totalToDate.raw)
      );
    }

    function roundOff(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    return {
      lineItems,
      addLineItem,
      deleteLineItem,
      updateLineItem,
      aggregate,
      allowMultipleLineItems,
      roundOff,
    };
  },
};
</script>

<style lang="postcss">
table.po-table {
  @apply w-full border-collapse mt-16;
}
table.po-table th:first-child {
  @apply rounded-tl-2xl;
}
table.po-table th:last-child {
  @apply rounded-tr-2xl;
}
table.po-table th {
  @apply bg-black text-white rounded-none font-poppins font-semibold text-lg py-2 px-4;
}
table.po-table tr.readonly td:not(:first-child) {
  @apply bg-gray-100;
}
table.po-table td[colspan] {
  @apply p-2 cursor-pointer;
}
table.po-table td {
  @apply border border-black p-4;
}
table.po-table td:not(:first-child) {
  @apply text-center;
}
table.po-table td:not(:first-child) input {
  @apply text-center;
}
</style>
