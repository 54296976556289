<template>
  <div class="text-right my-4">
    <a :href="pdfSource" target="_blank">
      <external-link-icon class="w-4 h-4 inline-block" />
    </a>
  </div>
  <div class="flex flex col justify-between items-center">
    <h1 class="font-bold">Page {{ page }} of {{ pageCount }}</h1>
    <div>
      <Button
        :disabled="page <= 1"
        @click="page--"
        :variant="gray"
        class="rounded-r-none rounded-l-none border-r-0 border-l-0"
        >Pre</Button
      >
      <Button
        :disabled="page >= pageCount"
        @click="page++"
        :variant="gray"
        class="rounded-r-none rounded-l-none border-r-0 border-l-0"
        >Next</Button
      >
    </div>
  </div>
  <div class="mt-4">
    <vue-pdf-embed
      ref="pdfRef"
      :source="pdfSource"
      :page="page"
      @rendered="handleDocumentRender"
    />
  </div>
</template>
<script>
import { ref } from 'vue';
import Button from '@/components/elements/Button.vue';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  props: {
    pdfSource: {
      type: String,
      default: () => '',
    },
  },
  components: {
    Button,
    VuePdfEmbed,
  },
  setup() {
    var pageCount = ref(1);
    var page = ref(1);
    const pdfRef = ref(null);

    function handleDocumentRender() {
      pageCount.value = pdfRef.value.pageCount;
    }

    function cacheBustTime() {
      return Date.now();
    }

    return {
      pageCount,
      page,
      handleDocumentRender,
      pdfRef,
      cacheBustTime,
    };
  },
};
</script>

<style lang="postcss" scoped>
.vue-pdf-embed > div {
  @apply mb-2 shadow-md;
}
</style>
