<template>
  <loading 
      v-model:active="isLoading"
      :can-cancel="false" 
      color="RGB(246, 191, 76)"
      :is-full-page="fullPage"
  />
  <Form
    action="/invoices"
    method="put"
    :param="param"
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    :feedback="false"
    @success="handleSuccess"
    @error="handleError"
  >
    <template #default="{ setFieldValue }">
      <Field type="hidden" name="ukzId" />
      <Field type="hidden" name="contactId" />
      <Field type="hidden" name="customerContactId" />
      <Field type="hidden" name="ukzNumber" v-model="ukzNumber" />
      <Field type="hidden" name="lineItems" v-model="lineItems" />
      <section class="grid grid-cols-2 justify-betwee gap-4">
        <div class="space-y-8">
          <Field
            component="textarea"
            name="csmContact"
            label="CSM Mailing and Contact Information:"
            :modelValue="formattedCSMContact"
            autosize
            bold
            standalone
            readonly
          />
          <Field
            component="textarea"
            name="csmContact"
            label="Pfizer Mailing and Contact Information:"
            :modelValue="formattedPfizerContact"
            autosize
            bold
            standalone
            readonly
          /> 
          <Field
            component="textarea"
            name="description"
            placeholder="UKZ12345 123 Project Name Goes Here"
            :modelValue="description"
            :readonly="!allowEditInvoice()"
          >
            <template #label="{ fieldName }">
              <label :for="fieldName" class="label">
                <strong>Description</strong> (UKZ Number and Project Name):
              </label>
            </template>
          </Field>
        </div>
        <div class="space-y-4">
          <Field
            label="Job Number:"
            placeholder="12345-67"
            inline
            bold
            standalone
            :modelValue="initialValues && initialValues.ukz.jobNumber"
            readonly
          />
          <Field
            name="invoiceNumber"
            label="Invoice Number:"
            placeholder="12345-67"
            inline
            bold
            :readonly="!allowEditInvoice()"
          />
          <Field
            name="invoiceDate"
            label="Invoice Date:"
            placeholder="00/00/0000"
            type="date"
            inline
            bold
            :readonly="!allowEditInvoice()"
          />
          <Field
            name="servicesThrough"
            label="Services Through:"
            placeholder="00/00/0000"
            type="date"
            inline
            bold
            :readonly="!allowEditInvoice()"
          />
          <Field
            label="UKZ Number:"
            placeholder="12-345"
            v-model="ukzNumber"
            inline
            bold
            standalone
            @update:modelValue="setFieldValue('ukzNumber', ukzNumber)"
            :readonly="!allowEditInvoice()"
          />
          <Field
            name="ukz.poNumber"
            label="Pfizer PO Number:"
            placeholder="123456789"
            v-model="poNumber"
            inline
            bold
            standalone
            @update:modelValue="setFieldValue('ukz.poNumber', poNumber)"
            :readonly="!allowEditInvoice()"
          />
          <Field
            name="dueDate"
            label="Due Date:"
            placeholder="Net 90"
            inline
            bold
            :modelValue="paymentTerms && paymentTerms.dueDate"
            readonly
          />
          <Field
            name="projectDirector"
            label="Project Director:"
            placeholder="First Last Name"
            :modelValue="approvalSignature && approvalSignature.approverName"
            inline
            bold
            standalone
            readonly
          />
          <Field
            name="projectManager"
            label="Pfizer EPM:"
            placeholder="First Last Name"
            :modelValue="initialValues && initialValues.ukz.pfizerEpm"
            inline
            bold
            standalone
            readonly
          />
          <!--<div class="justify-self-end text-right">
            <input
              id="multiLineItem"
              class="cursor-pointer"
              type="checkbox"
              v-model="allowMultipleLineItems"
            />
            <label
              for="multiLineItem"
              class="block font-poppins font-semibold inline-block ml-2 cursor-pointer"
            >
              Multi-Line Item
            </label>
          </div> -->
        </div>
      </section>
      <PurchaseOrderTable
        :line-items-data="lineItems"
        :invoice-data="initialValues"
        @update="updateLineItems"
      />
      <section class="grid grid-cols-2 justify-betwee gap-32 mt-8">
        <Field
          name="payableTo"
          label="Make all checks payable to:"
          placeholder="Payable To"
          bold
          :readonly="!allowEditInvoice()"
          :modelValue="paymentTerms && paymentTerms.payableTo"
        />
        <Field
          name="notes"
          component="textarea"
          label="Invoice Notes:"
          placeholder="Invoice Notes"
          autosize
          bold
          :readonly="!allowEditInvoice()"
          :modelValue="invoiceTerms && invoiceTerms.notes"
        />
      </section>
      <div class="text-right mt-12" v-if="allowEditInvoice()">
        <Button>Save Changes</Button>
      </div>
    </template>
  </Form>
</template>

<script>
import { ref, provide, onMounted, watch} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { object, string, number, array, date } from 'yup';
import PurchaseOrderTable from '@/components/tables/purchaseOrderTable/PurchaseOrderTable.vue';
import { useToast, useAllow  } from '@/helpers/composables';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import constant from '@/helpers/constant';

export default {
  components: {
    PurchaseOrderTable,
    Loading
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    allowMultiLineInvoice: {
      type: Boolean,
      default: () => (false)
    },
  },

  emits: ['refresh','updateLineItems','generatePDF'],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const initialValues = ref();
    const lineItems = ref([]);
    const allowMultipleLineItems = ref(false);
    const formattedCSMContact = ref();
    const formattedPfizerContact = ref();
    const paymentTerms = ref();
    const invoiceTerms = ref();
    const ukzNumber = ref();
    const approvalSignature = ref();
    const isLoading= ref(false);
    const fullPage = ref(true);
    const status = ref(constant.INVOICE_STATUS);
    const poNumber = ref();
    provide('allowMultipleLineItems', allowMultipleLineItems);

    const { id: param } = route.params;

    const allowEditInvoice = () => allow('invoices', 'create|edit');

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    watch(
      () => props.allowMultiLineInvoice,
      (newValue) => {
        allowMultipleLineItems.value = newValue;
      }
    );
    
    onMounted(() => {
      init(props.data);
    });

    async function init(data) {
      initialValues.value = data;
      ukzNumber.value = initialValues.value.ukz.ukzNumber;
      initialValues.value.ukzNumber = ukzNumber.value;
      poNumber.value = initialValues.value.ukz.poNumber;
      if(!initialValues.value.description){
        initialValues.value.description = initialValues.value.ukz.ukzNumber + ' ' + initialValues.value.ukz.projectName;
      }
      if(allowEditInvoice()){
        if (data.lineItems && data.lineItems.length > 1 || initialValues.value.status.phrase == status.value.OFFLINE) {
          allowMultipleLineItems.value = true;
        }
      }

      if (data.lineItems && data.lineItems.length) {
        lineItems.value = data.lineItems;
      } else {
        lineItems.value = [
          {
            invoiceId: data.id,
            scheduledValue: data.ukz.scheduledValue,
            previousInvoices: 0,
            currentInvoice: 0,
          },
        ];
      }

      const [csmContact, pfizerContact, approveSignature] = await Promise.all([
        store.dispatch('get', {
          action: `/presets/contacts/${data.contactId}`,
        }),
        store.dispatch('get', {
          action: `/presets/contacts/${data.customerContactId}`,
        }),
        store.dispatch('get', {
          action: `/presets/signature/1`,
        }),
      ]);

      if (!data.notes) {
        const [paymentTerm, invoiceTerm] = await Promise.all([
          store.dispatch('get', {
            action: `/presets/payment-terms/1`,
          }),
          store.dispatch('get', {
            action: `/presets/invoice-terms/1`,
          }),
        ]);
        paymentTerms.value = paymentTerm.data;
        invoiceTerms.value = invoiceTerm.data;
      }

      formattedCSMContact.value = csmContact.data.formatted;
      formattedPfizerContact.value = pfizerContact.data.formatted;
      approvalSignature.value = approveSignature.data;
    }

    function updateLineItems(items) {
      lineItems.value = items.value;
      emit('updateLineItems', true);
    }

    function handleSuccess({ message }) {
      toast.show(message);
      isLoading.value = false;
      emit('generatePDF', true);
    }  

    function handleError({ errors }) {
      toast.show(errors[0].message, 'error');
    }

    const validationSchema = object({
      ukzId: number().integer().required(),
      ukzNumber: string().required(),
      invoiceNumber: string()
        .required()
        .typeError('${path} should be a valid ${type}'),
      invoiceDate: date()
        .required()
        .typeError('${path} should be a valid ${type}'),
      servicesThrough: date()
        .required()
        .typeError('${path} should be a valid ${type}'),
      dueDate: string().required(),
      payableTo: string().required(),
      notes: string().required(),
      contactId: number().required(),
      customerContactId: number().required(),
      lineItems: array().of(
        object().shape({
          description: string().required(),
          scheduledValue: number().required(),
          previousInvoices: number().required(),
          currentInvoice: number().required(),
        })
      ),
    });

    return {
      param,
      initialValues,
      validationSchema,
      allowMultipleLineItems,
      lineItems,
      updateLineItems,
      handleSuccess,
      handleError,
      formattedCSMContact,
      formattedPfizerContact,
      ukzNumber,
      paymentTerms,
      invoiceTerms,
      approvalSignature,
      fullPage,
      isLoading,
      status,
      poNumber,
      allowEditInvoice
    };
  },
};
</script>
