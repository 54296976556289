<template>
  <section class="flex justify-between items-start lg:px-20 lg:py-10">
    <div class="space-y-8 w-1/2">
      <router-link
        to="/dashboard"
        class="font-poppins text-xl text-gray-400 underline"
        >&LT; Back to Invoices</router-link
      >
      <h1 class="text-4xl font-poppins font-normal">
        Invoice #{{ data && data.invoiceNumber }}
      </h1>
      <p>
        {{ data && data.description }}
      </p>
      <p class="text-base font-poppins font-normal" >Status: {{ data.status.title }}</p>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

};
</script>

