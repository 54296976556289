<template>
  <section class="flex justify-between items-start lg:px-20">
    <div class="space-y-4 w-1/2">
      <router-link
        to="/dashboard"
        class="font-poppins text-xl text-gray-400 underline"
        >&LT; Back to Invoices</router-link
      >
      <h1 class="text-4xl font-poppins font-normal">
        Invoice #{{ data && data.invoiceNumber }}
      </h1>
      <p>
        {{ data && data.description }}
      </p>
    </div> 
    <div v-if="data && data.status.phrase != invoiceStatus.INVOICE_ISSUED && allowEditInvoice()">
      <Dropdown :items="actionItems">
        <template #trigger="{ handleToggle }">
          <Button
            @click="handleToggle"
            variant="primary"
            class="w-48 font-bold font-poppins"
          >
            Actions
            <template #append>
              <angle-down-icon class="text-black w-4 h-4" />
            </template>
          </Button>
        </template>
      </Dropdown>
    </div>
  </section>
  <PreviewPDFModal />
  <ReviewSignModal @updateStatus="updateStatus"/>
  <RevisionNotesModal @updateRevisionNotes="updateNotes"/>
  <ConfirmModal id="confirm-multiline" title="Confirm Multi-Line Invoice" />
</template>

<script>
import { computed, ref } from 'vue';
import Dropdown from '@/components/dropdowns/Dropdown.vue';
import { useModal } from '@/helpers/composables';
import { useStore } from 'vuex';
import PreviewPDFModal from '../overlays/modals/PreviewPDFModal.vue';
import ReviewSignModal from '../overlays/modals/ReviewSignModal.vue';
import RevisionNotesModal from  '../overlays/modals/RevisionNotesModal.vue';
import { useToast, useAllow } from '@/helpers/composables';
import { useRoute } from 'vue-router';
import constant from '@/helpers/constant';

export default {
  components: {
    Dropdown,
    PreviewPDFModal,
    ReviewSignModal,
    RevisionNotesModal
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['updateRevisionNotes'],
  setup(props , { emit }) {
    const store = useStore();
    const toast = useToast();
    const route = useRoute();
    var item = ref();
    const { user }  = store.state.user;
    const { open: openPreviewPDFModal } = useModal('preview-pdf');
    const { open: openReviewSignModal } = useModal('review-sign');
    const {open: openRevisionsNoteModal} = useModal('revisions-note');
    const invoicePDFSource = ref();
    const invoiceStatus = constant.INVOICE_STATUS;
    const notificationMessage = ref('');
    const { open: openConfirmMuiltLineModal } = useModal('confirm-multiline');
    const { id: param } = route.params;
    const allowEditInvoice = () => allow('invoices', 'create|edit');


    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }
    const actionItems = computed(() => {
      if(props.data.status.phrase == invoiceStatus.IN_PROGRESS || props.data.status.phrase == invoiceStatus.AWAITING_REVISION){
        item = [
          {
            label: 'Send for Approval',
            onclick: (e) => {
              e.stopPropagation();
              updateInvoiceStatus(invoiceStatus.AWAITING_APPROVAL_CSM);
              createNotification(constant.NOTIFICATION_TYPE.AWAITING_APPROVAL_CSM,'Awaiting Approval - CSM');
            },
          },
          {
            label: 'Preview PDF',
            onclick: () => {
             openPreviewPDFModal();
            },
          },
          {
            label: 'Download PDF',
            onclick: (e) => {
              e.stopPropagation();
              downloadPDF();
            },
          },
          {
            label: 'Convert to Multi-line Invoice',
            onclick: (e) => {
              e.stopPropagation();
              openConfirmMuiltLineModal({
                body: `Once you convert to a multi-line invoice, this will be tracked offline. This cannot be undone for this invoice`,
                confirm: {
                  text: 'Confirm',
                  action: ({ close }) => {
                    updateInvoiceStatus(invoiceStatus.OFFLINE);
                    close();
                  },
                },
              });
            },
          },
        ];
      }
      if(props.data.status.phrase == invoiceStatus.AWAITING_APPROVAL_CSM){
        item = [
          {
            label: 'Approve and Sign',
            onclick: (e) => {
              e.stopPropagation();
              openReviewSignModal();
            },
          },
          {
            label: 'Send Back Revisions',
            onclick: (e) => {
              e.stopPropagation();
              openRevisionsNoteModal();
            },
          },
          {
            label: 'Download PDF',
            onclick: (e) => {
              e.stopPropagation();
              downloadPDF();
            },
          },
        ];
      }
      if(props.data.status.phrase == invoiceStatus.AWAITING_APPROVAL_AP){
        item = [
          {
            label: 'Approve',
            onclick: (e) => {
              e.stopPropagation();
              updateInvoiceStatus(invoiceStatus.APPROVED);
              createNotification(constant.NOTIFICATION_TYPE.SEND, 'Send');
            },
          },
          {
            label: 'Send Back Revisions',
            onclick: (e) => {
              e.stopPropagation();
              openRevisionsNoteModal();
            },
          },
          {
            label: 'Download PDF',
            onclick: (e) => {
              e.stopPropagation();
              downloadPDF();
            },
          },
        ];
      }

      if(props.data.status.phrase == invoiceStatus.APPROVED){
        item = [
          {
            label: 'Download PDF',
            onclick: (e) => {
              e.stopPropagation();
              downloadPDF();
            },
          },
        ];
      }
      
      return item;
    });

    async function downloadPDF() {
      const { data:invoiceData } = await store.dispatch('get', {
        action: '/invoices',
        param,
      });
      var downloadLink = document.createElement("a");
      downloadLink.href = invoiceData.documentUrl;
      downloadLink.target = '_blank';
      downloadLink.click();
    }
    function updateNotes(event){
      updateInvoices(event);
    }
    function updateInvoices(event) {
      emit('updateRevisionNotes', event); 
    }
    function updateStatus() {
      emit('updateStatus', true);
    }

    async function updateInvoiceStatus(status){
      const formData = {
        'statusId': status,
          'redirectLink' : window.location.href
      };
      console.log("formData", formData);
      try {
        const response = await store.dispatch('patch', {
        action: `/invoices/${param}`,
        formData,
        });
        toast.show(response.message);
        updateStatus();
      }
      catch(err){
        console.log(err);
      }
    }

    async function createNotification(type, message = ''){
      const formData = {
        type:type,
        message:message,
        read:false,
        userId:user.id,
        invoiceId: param,
      };
      try {
        await store.dispatch('post', {
          action: `/notifications`,
          formData,
        });
        toast.show('Notification created');
      }
      catch(err){
        console.log(err);
      }
    }

    return {
      actionItems,
      item,
      invoicePDFSource,
      downloadPDF,
      updateNotes,
      invoiceStatus,
      updateStatus,
      updateInvoiceStatus,
      user,
      notificationMessage,
      allowEditInvoice
    };
  },
};
</script>

<style lang="postcss" scoped>
.steps {
  @apply mt-16 grid grid-cols-4;
}
.step {
  @apply w-full flex justify-start items-center relative left-1/2;
}
.step > div {
  @apply relative;
}
.step .indicator {
  @apply bg-gray-300 text-gray-500 w-12 h-12 text-center font-poppins font-semibold flex flex-col justify-around relative z-10;
  border-radius: 50%;
}
.step .label {
  @apply absolute top-full text-center mt-2 left-1/2 transform -translate-x-1/2;
}
.line {
  @apply border-gray-300 border-b-2 h-2 block relative flex-1;
}
.step.complete .indicator {
  @apply bg-black text-white;
}
.step.active .indicator {
  @apply bg-yellow-500 text-black;
}
.step .label {
  @apply text-gray-500 font-poppins whitespace-nowrap;
}
.step.active .label {
  @apply text-black font-semibold;
}
.step.complete .label {
  @apply text-black;
}
.step.complete .line {
  @apply border-black;
}

.tab {
  @apply text-black font-poppins text-2xl text-center font-medium w-1/3 py-6 border-b-8 border-yellow-500 transition-all duration-300 ease-out;
}
.tab.disabled {
  @apply text-gray-400 border-transparent;
}
</style>
