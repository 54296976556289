<template>
  <tr class="relative" :class="{ highlight: index === 0 }">
    <td>
      <span class="font-poppins text-gray-700 font-semibold uppercase">
        Line Item {{ index + 1 }}
      </span>
      <input
        type="text"
        class="mt-2"
        placeholder="Item details"
        v-model="payload.description"
        @input="handleChange"
        :readonly="!allowEditInvoice()"
        :class="!allowEditInvoice() ? 'border-none' : ''"
      />
    </td>
    <td class="bg-gray-50">
      <input
        type="text"
        placeholder="Scheduled value"
        v-model="scheduledValueFormatted"
        @input="handleChange"
        readonly
        class="border-none bg-gray-50"
      />
    </td>
    <td class="bg-gray-50">
      <input
        type="text"
        placeholder="Previous invoices"
        v-model="previousInvoicesFormatted"
        @input="handleChange"
        readonly
        class="border-none bg-gray-50"
      />
    </td>
    <td>
      <input
        type="text"
        placeholder="Current invoice"
        v-model="currentInvoice"
        @input="handleChange"
        :readonly="!allowEditInvoice()"
        :class="!allowEditInvoice() ? 'border-none' : ''"
      />
    </td>
    <td class="readonly">
      <input type="text" v-model="totalToDateFormatted" readonly />
    </td>
    <td class="readonly">
      <input
        type="text"
        v-model="percentComplete"
        @input="handleChange"
        readonly
      />
    </td>
    <button v-if="index !== 0" class="cursor-pointer" @click="deleteLineItem">
      <delete-icon
        class="absolute w-6 h-6 top-1/2 transform -translate-y-1/2 -right-12"
      />
    </button>
  </tr>
</template>

<script>
import { reactive, computed, onMounted } from 'vue';
import { useNumberWithCommas } from '@/helpers/composables';
import { number, numberWithCommasAndDecimal } from '@/helpers/math';
import { useAllow } from '@/helpers/composables';
import { useStore } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: () => 1,
    },
    invoiceData: Object,
    default: () => ({}),
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const store = useStore();
    const payload = reactive({
      id: props.item.id,
      invoiceId: props.item.invoiceId,
      description: props.item.description
        ? props.item.description
        : props.invoiceData.ukz.poNumber,
      scheduledValue: parseFloat(props.item.scheduledValue),
      previousInvoices: parseFloat(props.item.previousInvoices),
      currentInvoice: parseFloat(props.item.currentInvoice),
      totalToDate: parseFloat(props.item.totalToDate),
    });

    const scheduledValue = useNumberWithCommas(payload, 'scheduledValue');
    const previousInvoices = useNumberWithCommas(payload, 'previousInvoices');
    const currentInvoice = useNumberWithCommas(payload, 'currentInvoice');

    const totalToDate = computed(() =>
      number(
        roundOff(parseFloat(payload.previousInvoices)) +
          roundOff(parseFloat(payload.currentInvoice))
      )
    );
    const totalToDateFormatted = computed(() =>
      numberWithCommasAndDecimal(totalToDate.value)
    );
    const scheduledValueFormatted = computed(() => {
      return numberWithCommasAndDecimal(payload.scheduledValue);
    });
    const previousInvoicesFormatted = computed(() =>
      numberWithCommasAndDecimal(payload.previousInvoices)
    );

    const percentComplete = computed(() => {
      return `${calculatePercentage()}%`;
    });

    const allowEditInvoice = () => allow('invoices', 'create|edit');

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    function calculatePercentage() {
      return number(
        Math.round(
          (parseFloat(totalToDate.value) / parseFloat(payload.scheduledValue)) *
            100
        )
      );
    }
    onMounted(() => {
      handleChange();
    });

    function deleteLineItem() {
      emit('delete', { index: props.index, payload });
    }

    function handleChange() {
      if (payload.currentInvoice) {
        naturalRound(payload.currentInvoice.toString());
      }
      payload.percentage = calculatePercentage();
      emit('update', { index: props.index, payload });
    }

    function roundOff(number) {
      return Math.round((number + Number.EPSILON) * 100) / 100;
    }

    function naturalRound(number) {
      let dec = number.indexOf('.');
      let tooLong = number.length > dec + 3;
      let invalidNum = isNaN(parseFloat(number));

      if ((dec >= 0 && tooLong) || invalidNum) {
        payload.currentInvoice = parseFloat(number.slice(0, -1));
      }
    }

    return {
      payload,
      scheduledValue,
      previousInvoices,
      currentInvoice,
      totalToDateFormatted,
      scheduledValueFormatted,
      previousInvoicesFormatted,
      percentComplete,
      deleteLineItem,
      handleChange,
      allowEditInvoice,
      roundOff,
      naturalRound,
    };
  },
};
</script>

<style lang="postcss" scoped>
td {
  @apply align-bottom;
}
td:not(.readonly) input {
  @apply block
    w-full
    px-3
    py-2
    border 
    rounded-md
    border-gray-300
    placeholder-gray-500
    focus:outline-none 
    focus:ring-yellow-500
    focus:border-yellow-500;
}
td.readonly {
  @apply bg-gray-100;
}
td.readonly input {
  @apply bg-transparent cursor-default;
}
</style>
