<template>
    <div class="bg-white shadow rounded-md">
        <h3
            class="font-poppings font-semibold text-base px-8 py-4 rounded-t border-gray-500 bg-gray-500 text-white"
        >
            Revision Needed:
        </h3>
        <div class="px-20 py-8">
            <p>{{ revisionNotes }}</p>
            <Button
            variant="primary"
            class="w-36 font-bold font-poppins mt-8"
            @click="resolveRevision()"
            >Resolve 
            </Button>
        </div>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from '@/helpers/composables';
import constant from '@/helpers/constant';

export default {
    props: {
        revisionNotes: {
            type: String,
            default: () => '',
        }
    },
    emits:['resolved'],
    setup(props, { emit }) {
        const route = useRoute();
        const store = useStore();
        const toast = useToast();
        const { id: param } = route.params;
        const invoiceStatus = constant.INVOICE_STATUS;
        const { user }  = store.state.user;

        async function resolveRevision() {
            const { message } = await store.dispatch('post', { action: '/invoices/'+ param +'/resolve' });
            toast.show(message);
            emit('resolved', true);
            if(message){
                updateInvoiceStatus(invoiceStatus.AWAITING_APPROVAL_CSM);
                createNotification(constant.NOTIFICATION_TYPE.AWAITING_APPROVAL_CSM,'Awaiting Approval - CSM');
            }
        }

        async function updateInvoiceStatus(status){
            const formData = {
                'statusId': status,
                    'redirectLink' : window.location.href
            };
            try {
                const response = await store.dispatch('patch', {
                action: `/invoices/${param}`,
                formData,
                });
                toast.show(response.message);
                updateStatus();
            }
            catch(err){
                console.log(err);
            }
        }

        async function createNotification(type, message = ''){
            const formData = {
                type:type,
                message:message,
                read:false,
                userId:user.id,
                invoiceId: param,
            };
            try {
                await store.dispatch('post', {
                action: `/notifications`,
                formData,
                });
                toast.show('Notification created');
            }
            catch(err){
                console.log(err);
            }
        }
        
        return {
            resolveRevision,
            updateInvoiceStatus,
            createNotification,
            invoiceStatus,
            user
        };
    },
};
</script>