<template>
    <Modal id="revisions-note" title="Revisions">
        <template  #default="{ close }">
          <Form
            class="mt-4 space-y-10"
            :initial-values="initialValues"
            :validation-schema="validationSchema"
            method="post"
            :action="`/invoices/${param}/revise`"
            @success="handleSuccess($event, close)"
          >
            <Field
                class="mt-0"
                component="textarea"
                name="rejectionNotes"
                label="Revision Notes"
                autosize
                bold
            />
            <div class="text-center">
              <Button class="mr-2">Send Revision Notes</Button>
              <Button class="ml-2 w-1/3" variant="secondary" @click="close"
                  >Cancel</Button
              >
            </div>
          </Form>  
        </template>
    </Modal>
</template>

<script>
import { string, object } from 'yup';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { useToast } from '@/helpers/composables';
import constant from '@/helpers/constant';
import { useStore } from 'vuex';

export default {
  emits:['updateRevisionNotes'],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
   const { id: param } = route.params;
    const initialValues = ref({
      rejectionNotes: ''
    });
    const { user }  = store.state.user;
    const validationSchema = object().shape({
      rejectionNotes: string().required(),
    });

    function handleSuccess({ message }, close) {
      toast.show(message);
      updateInvoiceStatus(constant.INVOICE_STATUS.AWAITING_REVISION);
      createNotification(constant.NOTIFICATION_TYPE.AWAITING_REVISION, 'Awaiting Revision');
      close();
      emit('updateRevisionNotes', true);
    }
     async function updateInvoiceStatus(status){
      const formData = {
        'statusId': status,
          'redirectLink' : window.location.href
      };
      try {
        const response = await store.dispatch('patch', {
        action: `/invoices/${param}`,
        formData,
        });
        toast.show(response.message);
      }
      catch(err){
        console.log(err);
      }
    }

    async function createNotification(type, message = ''){
      const formData = {
        type:type,
        message:message,
        read:false,
        userId:user.id,
        invoiceId: param,
      };
      try {
        await store.dispatch('post', {
          action: `/notifications`,
          formData,
        });
        toast.show('Notification created');
      }
      catch(err){
        console.log(err);
      }
    }

    return {
      validationSchema,
      param,
      initialValues,
      handleSuccess,
      updateInvoiceStatus,
      createNotification,
      user
    };
  },
};
</script>
